@import "normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

:root {
  --black: #000;
  --white: #fff;
  --gray: #f5f5f6;
  --primary-color: #ff3e6c;
  --primary-text-color: #151515;
  --secondary-text-color: #717171;
  --primary-border-color: #d4d5d9;
  --input-border-color: #e0e0e0;
  --price: #ff3e6c;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Rubik", sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

body::-webkit-scrollbar {
  display: none;
}

button,
input,
[tabindex] {
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
}

a[href] {
  text-decoration: none;
  color: var(--text-black-color);
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
